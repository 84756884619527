/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import styles from './GoToTopBtn.module.scss'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

const displayBoundaryY = 400

const GoToTopBtn = () => {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (window.pageYOffset > displayBoundaryY) {
      setShowScroll(true)
    } else if (window.pageYOffset <= displayBoundaryY) {
      setShowScroll(false)
    }
  }

  const scrollTop = e => {
    e.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)

    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [])

  if (showScroll) {
    return (
      <a
        href=''
        className={styles.btn}
        onClick={scrollTop}
        aria-label='網頁頂端'
      >
        <ArrowUpwardIcon sx={{ fontSize: '40px' }}></ArrowUpwardIcon>
      </a>
    )
  }

  return null
}

export default GoToTopBtn
