import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import FeatureBar from "../../../components/What4SAI/FeatureBar";
import TwoBoxBanner from "../../../components/What4SAI/TwoBoxBanner";
import Layout from "../../../components/Layout";
import BenefitCard from "../../../components/BenefitCard/BenefitCard";
import GoToTopBtn from "../../../components/GoToTopBtn";

import styles from "./IAmClient.module.scss";

//import imgage from "/public/image/Solutions/IAmClient/IAMClientBackground.png";
const IAmClient = () => {
  const { t } = useTranslation("IAmClient");
  const banner = t("banner", { returnObjects: true });
  const benefitsInfo = t("benefitsSection", { returnObjects: true });
  const benefitsCards = benefitsInfo.cards;
  const marketAppFeature = t("market_app_feature", { returnObjects: true });
  const featureItems = marketAppFeature.items;
  return (
    <>
      {/*Section 1*/}
      <Layout>
        <div className={styles.s1wrap}>
          <div className={styles.s1container}>
            <TwoBoxBanner
              slogan={banner?.slogan}
              title={banner?.title}
              content={banner?.content}
              buttonLabel={banner?.buttonLabel}
              imageSrc={banner?.imageSrc}
              btnColor={"#1ea09c"}
            />
          </div>
        </div>
        {/*Section 2*/}
        {/* style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/image/Solutions/IAmClient/IAMClientBackground.png)`,
            }}
         */}
        <div className={styles.s2wrap}>
          <div className={styles.s2container}>
            <Typography className={styles.h1}>
              <span className={styles.span}>
                <CompareArrowsIcon
                  sx={{
                    fontSize: "xx-large",
                    marginRight: "15px",
                    color: "#0a2958",
                  }}
                ></CompareArrowsIcon>
                {marketAppFeature.main_title}
              </span>
            </Typography>
            {featureItems &&
              Array.isArray(featureItems) &&
              featureItems.map((feature) => (
                <div className={styles.gridcontainer} key={feature.index}>
                  <FeatureBar
                    key={feature.index}
                    index={feature.index}
                    title={feature.item_title}
                    content={feature.item_content}
                    image={feature.image}
                    infoBackgroundColor={"#e5f5f5"}
                  />
                </div>
              ))}
          </div>
        </div>
        {/*Section 3*/}
        <div className={styles.s4wrap}>
          <div className={styles.s4container}>
            <Typography className={styles.h1}>
              <span className={styles.span}>
                <CompareArrowsIcon
                  sx={{
                    fontSize: "xx-large",
                    marginRight: "15px",
                    color: "#0a2958",
                  }}
                ></CompareArrowsIcon>
                {benefitsInfo.main_title}
              </span>
            </Typography>
            <Grid container className={styles.gridcontainer}>
              {benefitsCards &&
                Array.isArray(benefitsCards) &&
                benefitsCards.map((data, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    className={styles.grid}
                  >
                    <BenefitCard
                      title={data.title}
                      content={data.content}
                      icon={data.image}
                      detailColor={"#05736f"}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  );
};
export default IAmClient;
