import React from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import styles from "./QuestionCard.module.scss";

const QuestionCard = ({
  speechBubbleImage,
  textKey,
  btnColor,
  onButtonClick,
  showAfter,
}) => {
  return (
    <div className={styles.speechBubbleCard}>
      <div className={process.env.PUBLIC_URL + styles.speechBubble}>
        <img src={speechBubbleImage} alt="Speech Bubble" />
      </div>
      <div className={styles.speechBubbleTextWrap}>
        <div className={styles.speechBubbleText}>{textKey}</div>
        <div className={styles.btnWrap}>
          {!showAfter ? (
            <ArrowCircleRightIcon
              className={styles.btnIcon}
              onClick={onButtonClick}
              style={{ color: btnColor }}
            />
          ) : (
            <ArrowCircleLeftIcon
              className={styles.btnIconAfter}
              onClick={onButtonClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
