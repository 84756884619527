import React from "react";

import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import Layout from "../../components/Layout";
import GoToTopBtn from "../../components/GoToTopBtn";

import styles from "./PrivatePolicy.module.scss";

const ServiceTerms = () => {
  const { t } = useTranslation("ServiceTerms");
  const pageTitle = t("title", { returnObjects: true });
  const abstract = t("abstract", { returnObjects: true });
  const terms = t("terms", { returnObjects: true });
  //console.log(abstract);
  console.log(terms[1].aticle);
  return (
    <>
      <Layout>
        <div className={styles.s1wrap}>
          <Typography className={styles.h1}>
            <span className={styles.span}>{pageTitle}</span>
          </Typography>
          {abstract &&
            abstract.length > 0 &&
            abstract.map((para, index) => (
              <Typography className={styles.p}>{para.paragraph}</Typography>
            ))}
          {terms.map((term, index) => (
            <div key={index} className={styles.termSection}>
              <Typography className={styles.h2}>{term.title}</Typography>
              {term.abstract.length > 0 && (
                <div className={styles.abstract}>
                  {term.abstract.map((abs, absIndex) => (
                    <Typography className={styles.p} key={absIndex}>
                      {abs.paragraph}
                    </Typography>
                  ))}
                </div>
              )}
              {term.aticle && term.aticle.length > 0 && (
                <ol className={styles.article}>
                  {term.aticle.map((art, artIndex) => (
                    <li>
                      <Typography className={styles.p}>{art.clause}</Typography>
                    </li>
                  ))}
                </ol>
              )}
            </div>
          ))}
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  );
};

export default ServiceTerms;
