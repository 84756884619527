import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles/global.scss";
import smoothscroll from "smoothscroll-polyfill";

import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Demo from "./pages/Demo/Demo";
import IAmClient from "./pages/What4SAI/IAmClient/IAmClient";
import IAmMerchant from "./pages/What4SAI/IAmMerchant/IAmMerchant";
import OurProducts from "./pages/Solutions/OurProducts/OurProducts";
import DataDrivenSolutions from "./pages/Solutions/DataDrivenSolutions/DataDrivenSolutions";
import JoinUs from "./pages/JoinUs/JoinUs";
import PrivatePolicy from "./pages/LegalPolicies/PrivatePolicy";
import ServiceTerms from "./pages/LegalPolicies/ServiceTerms";
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage";
import NotFound from "./pages/NotFound/NotFound";

smoothscroll.polyfill();

function App() {
  const { pathname } = useLocation();
  //const { i18n } = useTranslation()
  const { i18n } = useTranslation("Header");
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    const lang = i18n.language;
    if (lang === "zh") {
      document.body.style.fontFamily = "var(--font-chinese)";
    } else {
      document.body.style.fontFamily = "var(--font-default)";
    }
  }, [i18n.language]);

  useEffect(() => {
    const loadNamespaces = async () => {
      await i18n.loadNamespaces(["Header", "Home", "Footer"]);
      setReady(true);
    };
    loadNamespaces();
  }, [i18n]);

  if (!ready) return <div></div>;

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path='/solutions' element={<Solutions />} />
       */}
      <Route path="/i-am-client" element={<IAmClient />} />
      <Route path="/i-am-merchant" element={<IAmMerchant />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/about-us" element={<AboutUs />} />
      {/* <Route path='/services' element={<Services />} />
       */}
      <Route path="/our-products" element={<OurProducts />} />
      <Route
        path="/smart-market-distribution"
        element={<DataDrivenSolutions />}
      />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/private-policy" element={<PrivatePolicy />} />
      <Route path="/service-terms" element={<ServiceTerms />} />
      <Route path="/join-us" element={<JoinUs />} />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
