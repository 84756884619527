import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import styles from './BenefitCard.module.scss'

function BenefitCard({ title, content, icon, detailColor }) {
  return (
    <Card className={styles.root}>
      <CardContent
        className={styles.img_container}
        style={{ backgroundImage: `url(${icon})` }}
      ></CardContent>
      <CardContent className={styles.title_container}>
        <Typography variant='h6' component='div' className={styles.title}>
          {title}
        </Typography>
      </CardContent>
      <div className={styles.triangle}></div>
      <CardContent
        className={styles.content_container}
        sx={{ backgroundColor: detailColor }}
      >
        <Typography className={styles.content}>{content}</Typography>
      </CardContent>
    </Card>
  )
}

export default BenefitCard
