import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, Box } from '@mui/material'
import styles from './ThankYouPage.module.scss'

const ThankYouPage = () => {
  const { t } = useTranslation('ThankYouPage')
  const image = t('image', { returnObjects: true })
  const pageTitle = t('page_title', { returnObjects: true })
  const pageSubtitle = t('page_subtitle', { returnObjects: true })
  const homeBtn = t('btn_text', { returnObjects: true })
  const homeLink = t('btn_link', { returnObjects: true })

  return (
    <div className={styles.wrap}>
      <Box className={styles.box}>
        <img src={image} alt='Thank You' className={styles.img} />
        <Typography variant='h4' component='h1' className={styles.title}>
          {pageTitle}
        </Typography>
        <Typography variant='subtitle1' className={styles.subtitle}>
          {pageSubtitle}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          href={homeLink}
          className={styles.btn}
        >
          {homeBtn}
        </Button>
      </Box>
    </div>
  )
}

export default ThankYouPage
