import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Typography, Grid, Button, Divider } from '@mui/material'
import SolCard from '../../components/SolCard/SolCard' // 确保导入了你的 CustomCard 组件
import { useNavigate } from 'react-router-dom'

import Layout from '../../components/Layout'
import GoToTopBtn from '../../components/GoToTopBtn'
import KeyBenefitBar from '../../components/KeyBenefitBar/KeyBenefitBar'
import Banner from '../../components/Banner/banner'
import styles from './Home.module.scss'
import LabelImportantIcon from '@mui/icons-material/LabelImportant'

const Home = () => {
  const { t } = useTranslation('Home')
  const banner = t('banner', { returnObjects: true })
  const whyUs = t('why_us', { returnObjects: true })
  const reasons = whyUs.reasons
  const keyBenefits = t('key_benefits', { returnObjects: true })
  const benefitsInfo = keyBenefits.benefitsInfo
  const learnMoreBtn = keyBenefits.learn_more_btn
  const what4SIs = t('what_4S_is', { returnObjects: true })
  const what4SIsInfo = what4SIs.content
  const navigate = useNavigate()

  const handleNavigate = path => {
    navigate(path)
  }
  return (
    <>
      <Layout>
        <Banner
          title={banner.title}
          content={banner.content}
          buttonText={banner.buttonText}
          buttonLink={banner.buttonLink}
          backgroundImage={banner.backgroundImage}
        />
        <Container className={styles.s2root}>
          <Typography className={styles.s2title}>{whyUs.main_title}</Typography>
          <Grid container className={styles.s2gridcontainer}>
            {reasons &&
              Array.isArray(reasons) &&
              reasons.map((data, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  className={styles.s2grid}
                >
                  <SolCard
                    title={data.title}
                    content={data.content}
                    image={data.image}
                  />
                </Grid>
              ))}
          </Grid>
        </Container>
        <div
          className={styles.s3root}
          style={{ backgroundImage: `url(${what4SIs.background_image})` }}
        >
          <div className={styles.s3overlay}>
            <Grid container className={styles.s3gridcontainer}>
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                className={styles.s3grid_content}
              >
                <Typography className={styles.h3}>
                  {what4SIs.main_title}
                </Typography>

                {what4SIsInfo &&
                  Array.isArray(what4SIsInfo) &&
                  what4SIsInfo.map((info, index) => (
                    <React.Fragment key={index}>
                      <div className={styles.title_container}>
                        <LabelImportantIcon
                          className={styles.label}
                        ></LabelImportantIcon>
                        <Typography className={styles.h4}>
                          <span>{info.item_name}</span>
                        </Typography>
                      </div>
                      <Divider className={styles.divider}></Divider>
                      <Typography className={styles.p}>
                        {info.details}
                      </Typography>
                    </React.Fragment>
                  ))}
              </Grid>
              <Grid item xs={12} sm={5} md={5} className={styles.s3grid_img}>
                <img
                  src={what4SIs.image}
                  alt='what_4S_is'
                  className={styles.img}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={styles.s4root}>
          <div className={styles.s4wrap}>
            <Typography className={styles.s4title}>
              {keyBenefits.main_title}
            </Typography>
            {benefitsInfo &&
              Array.isArray(benefitsInfo) &&
              benefitsInfo.map((data, index) => (
                <KeyBenefitBar
                  key={index}
                  itemNumber={data.index}
                  title={data.title}
                  leftImage={data.leftImage}
                  leftText={data.leftText}
                  rightImage={data.rightImage}
                  rightText={data.rightText}
                />
              ))}
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleNavigate(learnMoreBtn?.link)}
              className={styles.s4btn}
            >
              {learnMoreBtn?.text}
            </Button>
          </div>
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  )
}

export default Home
