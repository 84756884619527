import React from 'react'
import { Container, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import styles from './banner.module.scss'

function Banner({ title, content, buttonText, buttonLink, backgroundImage }) {
  const navigate = useNavigate()

  const handleNavigate = path => {
    navigate(path)
  }
  return (
    <Container
      className={styles.root}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Grid container className={styles.content}>
        <Grid item xs={12} className={styles.item}>
          <Typography variant='h3' className={styles.title}>
            {title}
          </Typography>
          <Typography variant='subtitle1' className={styles.subtitle}>
            {content}
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleNavigate(buttonLink)}
            className={styles.button}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Banner
