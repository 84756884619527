import React from "react";
import { Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import styles from "./KeyBenefitBar.module.scss";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const KeyBenefitBar = ({
  itemNumber,
  title,
  leftImage,
  leftText,
  rightImage,
  rightText,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.styledText}>
          <span className={styles.itemNumber}>{itemNumber}</span>
          <span className={styles.itemName}>{title}</span>
        </div>
      </div>
      <Grid container className={styles.gridcontainer}>
        <Grid item xs={12} sm={5} className={styles.grid}>
          <Box className={styles.box}>
            <img src={leftImage} alt="" className={styles.img} />
            <Typography className={styles.text}>{leftText}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box display="flex" justifyContent="center">
            {matches ? (
              <KeyboardDoubleArrowRightIcon
                fontSize="large"
                sx={{ color: "#1ea09c" }}
              />
            ) : (
              <KeyboardDoubleArrowDownIcon
                fontSize="large"
                sx={{ color: "#1ea09c" }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} className={styles.grid}>
          <Box className={styles.box}>
            <img src={rightImage} alt="" className={styles.img} />
            <Typography className={styles.text}>{rightText}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default KeyBenefitBar;
