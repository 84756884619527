import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Hidden,
  Button,
  Drawer,
  List,
  ListItem,
  Divider,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

//import { useMediaQuery, useTheme } from '@mui/material'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.scss";
import LanguageBtn from "./LanguageBtn";
import { Turn as Hamburger } from "hamburger-react";

const Header = () => {
  const { t } = useTranslation("Header");
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [expanded, setExpanded] = useState(false);
  let closePopoverTimeout = null;

  //const theme = useTheme()
  //const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleDrawer = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePopoverOpen = (event, menu) => {
    clearTimeout(closePopoverTimeout);
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menu);
  };

  const handlePopoverClose = () => {
    closePopoverTimeout = setTimeout(() => {
      setAnchorEl(null);
      setCurrentMenu(null);
    }, 100); // Adjust the delay time as needed
  };

  const renderMenu = (menu) => (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && currentMenu === menu.menu_title}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableRestoreFocus
      disableScrollLock
      PaperProps={{
        onMouseLeave: handlePopoverClose,
        sx: {
          borderRadius: "0px",
          backgroundColor: "white",
          pointerEvents: "auto",
          marginTop: "15px",
        },
      }}
      sx={{
        pointerEvents: "none",
      }}
    >
      {menu.menu_item.map((item, index) => (
        <React.Fragment key={item.item_title}>
          <MenuItem
            component={Link}
            to={item.path}
            onClick={handlePopoverClose}
            sx={{
              fontSize: "14px",
              padding: "5px 40px 5px 20px",
              margin: "5px 0px",
              "&:hover": {
                backgroundColor: "#1ea09c",
                color: "#fff",
              },
            }}
          >
            {item.item_title}
          </MenuItem>
          {index < menu.menu_item.length - 1 && (
            <Divider sx={{ padding: "0px !important" }} />
          )}
        </React.Fragment>
      ))}
    </Popover>
  );

  const menuData = t("menu", { returnObjects: true });

  const desktopLinks = Object.keys(menuData).map((key) => (
    <div key={key}>
      {menuData[key].menu_item && menuData[key].menu_item.length > 0 ? (
        <div
          onMouseEnter={(e) => handlePopoverOpen(e, menuData[key].menu_title)}
          onMouseLeave={handlePopoverClose}
        >
          <Button className={styles.navLink} color="inherit">
            {menuData[key].menu_title}
          </Button>
          {Boolean(anchorEl) &&
            currentMenu === menuData[key].menu_title &&
            renderMenu(menuData[key])}
        </div>
      ) : (
        <Button
          key={key}
          className={styles.navLink}
          color="inherit"
          component={Link}
          to={menuData[key].path}
        >
          {menuData[key].menu_title}
        </Button>
      )}
    </div>
  ));

  const drawerItems = Object.keys(menuData).map((key) => (
    <div key={key} className={styles.drawerContainer}>
      {menuData[key].menu_item && menuData[key].menu_item.length > 0 ? (
        <Accordion
          expanded={expanded === key}
          onChange={handleAccordionChange(key)}
          className={styles.accordion}
          square
          disableGutters
          elevation={0}
          sx={{ backgroundColor: "transparent !important" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandCircleDownIcon
                sx={{
                  fontSize: "18px",
                  color: "#fff",
                }}
              />
            }
            aria-controls={`${key}-content`}
            id={`${key}-header`}
            className={styles.accordionSummary}
            sx={{
              minHeight: "unset",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiButton-root": {
                padding: "0px !important",
                margin: "0px !important",
                fontSize: "18px",
                textTransform: "none",
              },
            }}
          >
            <Button
              className={styles.drawerItems}
              color="inherit"
              onClick={(e) => handlePopoverOpen(e, key)}
              sx={{
                fontSize: "18px",
                fontWeight: "700 !important",
              }}
            >
              {menuData[key].menu_title}
            </Button>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "5px 0px",
              margin: "0px 20px",
              color: "#fff",
            }}
          >
            <List
              sx={{
                padding: "10px 0px 0px 0px",
                margin: "0px 10px",
              }}
            >
              {menuData[key].menu_item.map((subItem) => (
                <ListItem
                  button
                  key={subItem.item_title}
                  component={Link}
                  to={subItem.path}
                  className={styles.drawerSubItems}
                  onClick={handleDrawer}
                  sx={{
                    padding: "15px 0px",
                    fontSize: "18px",
                    color: "#fff",
                  }}
                >
                  {subItem.item_title}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ) : (
        <ListItem
          button
          key={key}
          component={Link}
          to={menuData[key].path}
          className={styles.drawerItems}
          onClick={handleDrawer}
        >
          {menuData[key].menu_title}
        </ListItem>
      )}
    </div>
  ));

  const contactButton = (
    <Button
      key={t("contact.menu_title")}
      className={styles.contactButton}
      color="inherit"
      component={Link}
      to={t("contact.path")}
      onClick={handleDrawerClose}
    >
      {t("contact.menu_title")}
    </Button>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar className={styles.whiteToolbar}>
          <section
            style={{ flexGrow: 1, display: "flex", alignItems: "center" }}
            onClick={handleDrawerClose}
          >
            <Link
              to="/"
              style={{ display: "inline-flex", alignItems: "center" }}
              className="logoLink"
            >
              <img
                src="/logo_full_4sai.svg"
                alt="Company Logo"
                style={{ height: "40px" }}
              />
            </Link>
          </section>
          <Hidden only={["xs", "sm", "md"]} implementation="css">
            <div className={styles.desktopLinkContainer}>{desktopLinks}</div>
          </Hidden>
          <section>
            <LanguageBtn key="LanguageBtn" />
          </section>
          <Hidden only={["xs", "sm", "md"]} implementation="css">
            {contactButton}
          </Hidden>
          <Hidden only={["lg", "xl"]} implementation="css">
            <div
              style={{
                backgroundColor: isOpen ? "#000000" : "#1ea09c",
                borderRadius: "40px",
                marginLeft: "3vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "background-color 0.8s ease",
                overflow: "hidden !important",
              }}
            >
              <Hamburger
                toggled={isOpen}
                toggle={setIsOpen}
                size={20}
                color={"white"}
              />
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden only={["lg", "xl"]} implementation="css">
        <Drawer
          className={styles.drawer}
          anchor="right"
          open={isOpen}
          onClose={handleDrawer}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
          classes={{
            paper: styles.drawerPaper,
          }}
        >
          <List className={styles.drawerList}>{drawerItems}</List>
          <Divider sx={{ borderColor: "#fff", borderWidth: 2 }} />
          <List className={styles.centerList}>{contactButton}</List>
        </Drawer>
      </Hidden>
    </>
  );
};

export default Header;
