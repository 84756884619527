import React from "react";
import { Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import Faq from "../../components/Faq/Faq";
import RetailRadialBar from "../../components/AboutUs/RetailRadialBar";
import MerchantNetwork from "../../components/AboutUs/MerchantNetwork";
import MemberAreaBump from "../../components/AboutUs/MemberAreaBump";
import MerchantPie from "../../components/AboutUs/MerchantPie";
import OverlappingBox from "../../components/AboutUs/OverlappingBox";
import ProfileCard from "../../components/AboutUs/ProfileCard";
import Layout from "../../components/Layout";
import GoToTopBtn from "../../components/GoToTopBtn";

import styles from "./AboutUs.module.scss";

const AboutUs = () => {
  const { t } = useTranslation("AboutUs");
  const banner = t("banner", { returnObjects: true });
  const whoWeAre = t("who_we_are", { returnObjects: true });
  const story = t("story", { returnObjects: true });
  const storyContent = story.content;
  const faq = t("faq", { returnObjects: true });
  const faqContent = faq.content;
  //console.log(merchantData);
  return (
    <>
      <Layout>
        <div className={styles.s1wrap}>
          <div className={styles.s1container}>
            <OverlappingBox
              slogan={banner?.slogan}
              title={banner?.title}
              content={banner?.content}
              buttonLabel={banner?.buttonLabel}
              imageSrc={banner?.imageSrc}
            />
          </div>
        </div>
        {/*who we are*/}
        <div className={styles.s2wrap}>
          <div
            className={styles.s2container}
            style={{ backgroundColor: "red !important" }}
          >
            <Typography className={styles.h1}>
              <span className={styles.span}>{whoWeAre?.main_title}</span>
            </Typography>
            {whoWeAre.content &&
              Array.isArray(whoWeAre.content) &&
              whoWeAre.content.map((para, index) => (
                <ProfileCard
                  key={index}
                  id={index}
                  avatar={para.avatar}
                  position={para.position}
                  name={para.name}
                  description={para.description}
                />
              ))}
          </div>
        </div>

        {/*Story*/}
        <div className={styles.s3wrap}>
          <Typography className={styles.h1}>
            <span className={styles.span}>{story?.main_title}</span>
          </Typography>
          <Typography className={styles.h2}>
            <span className={styles.span}>{story?.subtitle}</span>
          </Typography>
          {story.abstract &&
            Array.isArray(story.abstract) &&
            story.abstract.map((info, index) => (
              <Typography className={styles.p} key={index}>
                <span className={styles.span}>{info?.paragraph}</span>
              </Typography>
            ))}

          {storyContent &&
            Array.isArray(storyContent) &&
            storyContent.map((content, index) => (
              <React.Fragment key={index}>
                <Typography className={styles.h3}>
                  <span>{content.main_title}</span>
                </Typography>
                <Typography className={styles.time}>
                  <span>{content.time}</span>
                </Typography>
                <Divider className={styles.divider}></Divider>
                {content.paragraph &&
                  Array.isArray(content.paragraph) &&
                  content.paragraph.map((info, subIndex) => (
                    <Typography key={subIndex} className={styles.p}>
                      {info.text}
                    </Typography>
                  ))}
                {index === 0 && (
                  <div style={{ height: "400px" }}>
                    <RetailRadialBar data={content.data}></RetailRadialBar>
                  </div>
                )}
                {index === 1 && (
                  <div style={{ width: "100%", height: "350px" }}>
                    <MerchantNetwork data={content.data}></MerchantNetwork>
                  </div>
                )}
                {index === 2 && (
                  <div style={{ width: "100%", height: "300px" }}>
                    <MemberAreaBump data={content.data}></MemberAreaBump>
                  </div>
                )}
                {index === 3 && (
                  <div style={{ height: "400px" }}>
                    <MerchantPie data={content.data}></MerchantPie>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>

        <div className={styles.s6wrap}>
          <div className={styles.s6container}>
            <Typography className={styles.h1}>
              <span className={styles.span}>
                <QuestionAnswerIcon
                  sx={{
                    fontSize: "x-large",
                    marginRight: "15px",
                    color: "#05736f",
                  }}
                ></QuestionAnswerIcon>
                {faq.main_title}
              </span>
            </Typography>
            <div className={styles.faqcontainer}>
              {faqContent &&
                Array.isArray(faqContent) &&
                faqContent.map((info, index) => (
                  <Faq key={index} question={info.Q} answer={info.A}></Faq>
                ))}
            </div>
          </div>
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  );
};
export default AboutUs;
