import React from 'react'
import { Box, Typography, Paper } from '@mui/material'

import styles from './OverlappingBox.module.scss'

const OverlappingBox = ({ slogan, title, content, buttonLabel, imageSrc }) => {
  return (
    <Paper className={styles.wrap}>
      <Box
        style={{ backgroundImage: `url(${imageSrc})` }}
        className={styles.boxImg}
      ></Box>
      <Box className={styles.boxInfo}>
        <Typography
          variant='h4'
          component='h2'
          gutterBottom
          className={styles.slogan}
        >
          {slogan}
        </Typography>
        <Typography
          variant='h4'
          component='h1'
          gutterBottom
          className={styles.title}
        >
          {title}
        </Typography>
        <Typography variant='body1' paragraph className={styles.content}>
          {content}
        </Typography>
      </Box>
    </Paper>
  )
}

export default OverlappingBox
