import React from "react";
import { useTranslation } from "react-i18next";

import SolSection from "../../../components/Solutions/DataDrivenSolutions/SolSection";
import TwoBoxBanner from "../../../components/Solutions/DataDrivenSolutions/TwoBoxBanner";
import Layout from "../../../components/Layout";
import GoToTopBtn from "../../../components/GoToTopBtn";

import styles from "./DataDrivenSolutions.module.scss";

const SmartMarketDistribution = () => {
  const { t } = useTranslation("DataDrivenSolutions");
  const banner = t("banner", { returnObjects: true });
  const smartMarketing = t("smartMarketingSection", { returnObjects: true });
  const merchantMarket = t("merchantMarketSection", { returnObjects: true });
  return (
    <>
      <Layout>
        <div className={styles.s1wrap}>
          <div className={styles.s1container}>
            <TwoBoxBanner
              slogan={banner?.slogan}
              title={banner?.title}
              content={banner?.content}
              buttonLabel={banner?.buttonLabel}
              imageSrc={banner?.imageSrc}
            />
          </div>
        </div>
        {/*Section 2*/}
        <div className={styles.s2wrap}>
          <div className={styles.s2container}>
            <SolSection
              data={smartMarketing}
              backgroundColor={"#e3edf5"}
            ></SolSection>
          </div>
        </div>
        {/*Section 4*/}
        <div className={styles.s3wrap}>
          <div className={styles.s3container}>
            <SolSection
              data={merchantMarket}
              backgroundColor={"#fdfeff"}
            ></SolSection>
          </div>
        </div>
        {/*Section 3*/}
        {/* <div className={styles.s5wrap}>
          <div className={styles.s5container}>
            <Button
              variant='outlined'
              color='primary'
              href={'/demo'}
              className={styles.btn}
            >
              {btnInfo.demo}
            </Button>
            <Button
              color='primary'
              href={'/join-us'}
              className={styles.disabledbtn}
              variant='outlined'
              disabled
            >
              {btnInfo.join}
            </Button>
          </div>
        </div> */}
      </Layout>
      <GoToTopBtn />
    </>
  );
};
export default SmartMarketDistribution;
