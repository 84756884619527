// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/network
import React from "react";

import { ResponsiveAreaBump } from "@nivo/bump";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MemberAreaBump = ({ data /* see data tab */ }) => {
  //console.log("0000000");
  //console.log(data);
  return (
    <ResponsiveAreaBump
      data={data}
      margin={{ top: 40, right: 20, bottom: 40, left: 60 }}
      spacing={8}
      colors={{ scheme: "set2" }}
      blendMode="multiply"
      startLabel={true}
      startLabelPadding={5}
      endLabel={false}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        truncateTickAt: 0,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        truncateTickAt: 0,
      }}
    />
  );
};
export default MemberAreaBump;
