import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './LanguageBtn.module.scss'

const LanguageBtn = () => {
  const { i18n } = useTranslation()

  const toggleLanguage = event => {
    const newLang = i18n.language === 'en' ? 'zh' : 'en'
    event.stopPropagation()
    i18n.changeLanguage(newLang)
    localStorage.setItem('language', newLang)
  }

  return (
    <>
      <Typography
        component='span'
        className={`${styles.languageToggle} ${
          i18n.language === 'en' ? '' : styles.active
        }`}
        onClick={toggleLanguage}
      >
        中
      </Typography>
      <Typography component='span' className={styles.languageSeparator}>
        |
      </Typography>
      <Typography
        component='span'
        className={`${styles.languageToggle} ${
          i18n.language === 'zh' ? '' : styles.active
        }`}
        onClick={toggleLanguage}
      >
        EN
      </Typography>
    </>
  )
}

export default LanguageBtn
