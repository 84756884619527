import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

import HelpInfo from "../../components/HelpInfo/HelpInfo";
import Layout from "../../components/Layout";
import GoToTopBtn from "../../components/GoToTopBtn";
import styles from "./ContactUs.module.scss";

const ContactUs = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    additional: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const result = await emailjs.send(
        "service_mo6bfyc", // 替换为你的 EmailJS 服务 ID
        "template_8j2jyzp", // 替换为你的 EmailJS 模板 ID
        formData,
        "7XOimE5QfyXXIzdlR" // 替换为你的 EmailJS PUBLIC_KEY
      );
      console.log("Email successfully sent!", result.text);
      navigate("/thank-you");
    } catch (error) {
      console.log("Failed to send email.", error.text);
    }
  };

  const { t } = useTranslation("ContactUs");
  const pageTitle = t("page_title", { returnObjects: true });
  const pageSubtitle = t("page_subtitle", { returnObjects: true });
  const helpsInfo = t("helps", { returnObjects: true });
  const formInfo = t("form", { returnObjects: true });

  return (
    <>
      <Layout>
        <div className={styles.s1wrap}>
          <Typography className={styles.h1}>
            <span className={styles.span}>{pageTitle}</span>
          </Typography>
          <Typography className={styles.p}>{pageSubtitle}</Typography>
        </div>
        <div className={styles.s2wrap}>
          <Container className={styles.container}>
            <Grid container className={styles.gridcontainer}>
              <Grid item xs={12} md={6} className={styles.griditem}>
                <Typography className={styles.h4}>
                  <span className={styles.span}>{helpsInfo.main_title}</span>
                </Typography>
                <Typography className={styles.p}>
                  <span>{helpsInfo.main_subtitle}</span>
                </Typography>
                <div className={styles.info}>
                  {helpsInfo.content &&
                    Array.isArray(helpsInfo.content) &&
                    helpsInfo.content.map((content, index) => (
                      <HelpInfo
                        key={index}
                        title={content.item}
                        details={content.detail}
                      ></HelpInfo>
                    ))}
                </div>
              </Grid>
              <Grid item xs={12} md={6} className={styles.gridtable}>
                <Paper className={styles.papertable}>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      label={formInfo.first_name}
                      required
                      variant="outlined"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      style={{ marginBottom: "20px" }}
                      InputLabelProps={{
                        sx: {
                          "& .MuiInputLabel-asterisk": {
                            color: "red",
                          },
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      label={formInfo.last_name}
                      required
                      variant="outlined"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      style={{ marginBottom: "20px" }}
                      InputLabelProps={{
                        sx: {
                          "& .MuiInputLabel-asterisk": {
                            color: "red",
                          },
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      label={formInfo.email}
                      type="email"
                      required
                      variant="outlined"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      style={{ marginBottom: "20px" }}
                      InputLabelProps={{
                        sx: {
                          "& .MuiInputLabel-asterisk": {
                            color: "red",
                          },
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      label={formInfo.message}
                      required
                      multiline
                      rows={4}
                      variant="outlined"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      style={{ marginBottom: "20px" }}
                      InputLabelProps={{
                        sx: {
                          "& .MuiInputLabel-asterisk": {
                            color: "red",
                          },
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      label={formInfo.additional}
                      multiline
                      rows={2}
                      variant="outlined"
                      name="additional"
                      value={formData.additional}
                      onChange={handleInputChange}
                      style={{ marginBottom: "20px" }}
                      InputLabelProps={{
                        sx: {
                          "& .MuiInputLabel-asterisk": {
                            color: "red",
                          },
                        },
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      className={styles.submitbtn}
                    >
                      {formInfo.submit}
                    </Button>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  );
};

export default ContactUs;
