import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import { Typography } from "@mui/material";

import styles from "./Faq.module.scss";

function Faq({ question, answer }) {
  return (
    <div className={styles.wrap}>
      <Accordion className={styles.accordion}>
        <AccordionSummary
          className={styles.title}
          expandIcon={
            <ExpandCircleDownIcon sx={{ color: "#05736f" }} fontSize="large" />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className={styles.qawrap}>
            <div className={styles.symb}>Q:</div>
            <Typography className={styles.info}>{question}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <div className={styles.qawrap}>
            <div className={styles.symb}>A:</div>
            <div className={styles.info}>
              {answer.front.length > 0 && (
                <div>
                  {answer.front.map((para, paraIndex) => (
                    <Typography key={`front-${paraIndex}`} className={styles.p}>
                      {para.paragraph}
                    </Typography>
                  ))}
                </div>
              )}
              {answer.items && answer.items.length > 0 && (
                <ol className={styles.items}>
                  {answer.items.map((item, itemIdx) => (
                    <li key={`item-${itemIdx}`}>
                      <Typography className={styles.p}>{item.item}</Typography>
                    </li>
                  ))}
                </ol>
              )}
              {answer.back.length > 0 && (
                <div>
                  {answer.back.map((para, paraIndex) => (
                    <Typography key={`back-${paraIndex}`} className={styles.p}>
                      {para.paragraph}
                    </Typography>
                  ))}
                </div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Faq;
