// i18n.js
import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: "zh",
    fallbackLng: false,
    debug: true,
    ns: [
      "Header",
      "Footer",
      "Home",
      "ContactUs",
      "ThankYouPage",
      "AboutUs",
      "DataDrivenSolutions",
      "IAmMerchant",
      "IAmClient",
      "Demo",
      "OurProducts",
      "NotFound",
      "ServiceTerms",
      "PrivatePolicy",
    ],
    defaultNS: "Home",
    backend: {
      loadPath: "/Locale/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false, // to handle async loading of translations
    },
  });

export default i18n;
