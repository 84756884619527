import React, { useState } from "react";
import { Box, Avatar, Typography, Paper, Divider } from "@mui/material";

import styles from "./ProfileCard.module.scss";
import { styled } from "@mui/system";

const VerticalDivider = styled(Divider)(({ theme }) => ({
  height: "120px",
  width: "2px",
  backgroundColor: "#05736f",
}));

const ProfileCard = ({ id, name, avatar, position, description }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const maxLength = 70;
  return (
    <Paper className={styles.wrap}>
      <Box className={styles.avatarWrap}>
        <Avatar className={styles.avatar} src={avatar} alt={name} />
      </Box>
      <Box className={styles.dividerWrap}>
        <VerticalDivider orientation="vertical" flexItem />
      </Box>
      <Box className={styles.infoWrap} onClick={handleToggleExpand}>
        <Typography className={styles.h3}>{position}</Typography>
        <Typography className={styles.h6}>{name}</Typography>
        <Typography className={styles.p} variant="body2">
          {expanded ? description : `${description.substring(0, maxLength)}...`}
        </Typography>
        {/* {description.length > maxLength && (
          <Button variant="text" color="primary" onClick={handleToggleExpand}>
            {expanded ? "Show less" : "Show more"}
          </Button>
        )} */}
      </Box>
    </Paper>
  );
};

export default ProfileCard;
