import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import Layout from "../../components/Layout";
import GoToTopBtn from "../../components/GoToTopBtn";

import styles from "./Demo.module.scss";

import emailjs from "emailjs-com";

const Demo = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    position: "",
    aboutUs: "",
    communicationsConsent: false,
  });
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // 发送邮件
    emailjs
      .send(
        "service_mo6bfyc",
        "template_otcmvmf",
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          companyName: formData.companyName,
          email: formData.email,
          position: formData.position,
          aboutUs: formData.aboutUs,
          communicationsConsent: formData.communicationsConsent ? "Yes" : "No",
        },
        "7XOimE5QfyXXIzdlR"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          navigate("/thank-you");
        },
        (error) => {
          console.log("Failed to send email.", error.text);
        }
      );

    setFormData({
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      position: "",
      aboutUs: "",
      communicationsConsent: false,
    });
  };

  const { t } = useTranslation("Demo");
  const pageTitle = t("page_title", { returnObjects: true });
  const pageDescription = t("page_description", { returnObjects: true });
  const formInfo = t("form_info", { returnObjects: true });
  const posInfo = formInfo.position;
  const legal = formInfo.legal;
  return (
    <>
      <Layout>
        <div className={styles.s1wrap}>
          <Typography className={styles.h1}>
            <span className={styles.span}>{pageTitle}</span>
          </Typography>
          <Typography className={styles.p}>{pageDescription}</Typography>
        </div>
        <div className={styles.s2wrap}>
          <Paper className={styles.container}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <TextField
                label={formInfo.first_name}
                variant="outlined"
                name="firstName"
                required
                value={formData.firstName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                className={styles.textfield}
                InputLabelProps={{
                  sx: {
                    "& .MuiInputLabel-asterisk": {
                      color: "red",
                    },
                  },
                }}
              />
              <TextField
                label={formInfo.last_name}
                variant="outlined"
                name="lastName"
                required
                value={formData.lastName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                className={styles.textfield}
                InputLabelProps={{
                  sx: {
                    "& .MuiInputLabel-asterisk": {
                      color: "red",
                    },
                  },
                }}
              />
              <TextField
                label={formInfo?.company}
                variant="outlined"
                name="companyName"
                required
                value={formData.companyName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                className={styles.textfield}
                InputLabelProps={{
                  sx: {
                    "& .MuiInputLabel-asterisk": {
                      color: "red",
                    },
                  },
                }}
              />
              <TextField
                label={formInfo?.email}
                variant="outlined"
                name="email"
                type="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                className={styles.textfield}
                InputLabelProps={{
                  sx: {
                    "& .MuiInputLabel-asterisk": {
                      color: "red",
                    },
                  },
                }}
              />
              <FormControl
                fullWidth
                margin="normal"
                className={styles.formControl}
              >
                <InputLabel
                  className={styles.inputLabel}
                  required
                  sx={{
                    "& .MuiInputLabel-asterisk": {
                      color: "red",
                    },
                  }}
                >
                  {posInfo.label}
                </InputLabel>
                <Select
                  name="position"
                  value={formData.position}
                  onChange={handleInputChange}
                  required
                  label={posInfo?.label}
                  className={styles.select}
                >
                  {posInfo?.options &&
                    Array.isArray(posInfo.options) &&
                    posInfo.options.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.opt.toLowerCase().replace(/ /g, "")}
                      >
                        {option.opt}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                label={formInfo?.know_us}
                variant="outlined"
                name="aboutUs"
                value={formData.aboutUs}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                className={styles.textfield}
                InputLabelProps={{
                  sx: {
                    "& .MuiInputLabel-asterisk": {
                      color: "red",
                    },
                  },
                }}
              />
              <Typography className={styles.p}>
                {formInfo?.privacy_claim}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.communicationsConsent}
                    onChange={handleInputChange}
                    name="communicationsConsent"
                  />
                }
                label={formInfo?.agree_connect}
                className={styles.formControlLabel}
              />
              <Typography className={styles.p}>
                {formInfo?.additional_info}
                <a
                  href="/private-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {legal.content}
                  <sup className={styles.sup}>{legal.superscript}</sup>
                </a>
              </Typography>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
                className={styles.submitbtn}
              >
                {formInfo?.submit}
              </Button>
            </form>
          </Paper>
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  );
};

export default Demo;
