import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
} from '@mui/material'

import Layout from '../../components/Layout'
import GoToTopBtn from '../../components/GoToTopBtn'

import styles from './JoinUs.module.scss'

const Demo = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    position: '',
    aboutUs: '',
    communicationsConsent: false,
  })
  const handleInputChange = event => {
    const { name, value, type, checked } = event.target
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }
  const handleSubmit = event => {
    event.preventDefault()
    navigate('/thank-you')
    console.log('Form Data Submitted: ', formData)
    // Submit form data to your backend or API here
  }

  return (
    <>
      <Layout>
        <div className={styles.s1wrap}>
          <Typography variant='h1' className={styles.h1}>
            <span className={styles.span}>Book a Demo</span>
          </Typography>
          <Typography variant='p' className={styles.p}>
            Thank you for your interest in BoostDraft. Please fill in the form
            to get in touch with our sales experts. We’ll get back to you within
            a couple of business days.
          </Typography>
        </div>
        <div className={styles.s2wrap}>
          <Paper className={styles.container}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <TextField
                label='First Name*'
                variant='outlined'
                name='firstName'
                required
                value={formData.firstName}
                onChange={handleInputChange}
                fullWidth
                margin='normal'
                className={styles.textfield}
              />
              <TextField
                label='Last Name*'
                variant='outlined'
                name='lastName'
                required
                value={formData.lastName}
                onChange={handleInputChange}
                fullWidth
                margin='normal'
                className={styles.textfield}
              />
              <TextField
                label='Company Name*'
                variant='outlined'
                name='companyName'
                required
                value={formData.companyName}
                onChange={handleInputChange}
                fullWidth
                margin='normal'
                className={styles.textfield}
              />
              <TextField
                label='Email*'
                variant='outlined'
                name='email'
                type='email'
                required
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                margin='normal'
                className={styles.textfield}
              />
              <FormControl
                fullWidth
                margin='normal'
                className={styles.formControl}
              >
                <InputLabel className={styles.inputLabel}>Position*</InputLabel>
                <Select
                  name='position'
                  value={formData.position}
                  onChange={handleInputChange}
                  required
                  label='Position*'
                  className={styles.select}
                >
                  <MenuItem value='' className={styles.menuItem}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='partner'>Partner</MenuItem>
                  <MenuItem value='inHouseCounsel'>In-house counsel</MenuItem>
                  <MenuItem value='associate'>Associate</MenuItem>
                  <MenuItem value='paralegal'>Paralegal</MenuItem>
                  <MenuItem value='director'>Director</MenuItem>
                  <MenuItem value='sales'>Sales</MenuItem>
                  <MenuItem value='executive'>Executive</MenuItem>
                  <MenuItem value='knowledgeManagementIT'>
                    Knowledge management/IT
                  </MenuItem>
                  <MenuItem value='other'>Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label='How did you learn about us?*'
                variant='outlined'
                name='aboutUs'
                required
                value={formData.aboutUs}
                onChange={handleInputChange}
                fullWidth
                margin='normal'
                className={styles.textfield}
              />
              <Typography className={styles.p}>
                BoostDraft is committed to protecting and respecting your
                privacy, and we’ll only use your personal information to
                administer your account and to provide the products and services
                you requested from us. From time to time, we would like to
                contact you about our products and services, as well as other
                content that may be of interest to you. If you consent to us
                contacting you for this purpose, please tick below to say how
                you would like us to contact you:
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.communicationsConsent}
                    onChange={handleInputChange}
                    name='communicationsConsent'
                  />
                }
                label='I agree to receive other communications from BoostDraft*'
                className={styles.formControlLabel}
              />
              <Typography className={styles.p}>
                You can unsubscribe from these communications at any time. For
                more information on how to unsubscribe, our privacy practices,
                and how we are committed to protecting and respecting your
                privacy, please review our Privacy Policy.
              </Typography>

              <Button
                type='submit'
                variant='contained'
                color='primary'
                style={{ marginTop: 16 }}
                className={styles.submitbtn}
              >
                Submit
              </Button>
            </form>
          </Paper>
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  )
}

export default Demo
