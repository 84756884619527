import React from 'react'
import { Box, Typography, Button, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import styles from './TwoBoxBanner.module.scss'

const Banner = ({ slogan, title, content, buttonLabel, imageSrc }) => {
  const navigate = useNavigate()

  const handleNavigate = path => {
    navigate(path)
  }
  return (
    <Paper className={styles.wrap}>
      <Box className={styles.boxInfo}>
        <Typography
          variant='h4'
          component='h2'
          gutterBottom
          className={styles.slogan}
        >
          {slogan}
        </Typography>
        <Typography
          variant='h4'
          component='h1'
          gutterBottom
          className={styles.title}
        >
          {title}
        </Typography>
        <Typography variant='body1' paragraph className={styles.content}>
          {content}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          className={styles.btn}
          onClick={() => handleNavigate('/demo')}
        >
          {buttonLabel}
        </Button>
      </Box>
      <Box className={styles.boxImg}>
        <img src={imageSrc} alt='Detail' className={styles.img} />
      </Box>
    </Paper>
  )
}

export default Banner
