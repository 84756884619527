import React from 'react'
import { Card, CardContent, Typography, Divider } from '@mui/material'
import styles from './SolCard.module.scss'

function SolCard({ image, title, content }) {
  return (
    <Card className={styles.root}>
      <CardContent className={styles.cardcontent}>
        <img src={image} alt={title} className={styles.imgicon} />
        <Typography variant='h6' className={styles.title}>
          {title}
        </Typography>
        <Divider className={styles.divider} />
        <Typography variant='body2' className={styles.content}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default SolCard
