import React from "react";
import { Typography, Box, List, ListItem, ListItemText } from "@mui/material";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import styles from "./SolSection.module.scss";

const SolSection = ({ data, backgroundColor }) => {
  const details = data.details;
  const summary = data.summary;
  return (
    <div className={styles.s2wrap} style={{ backgroundColor }}>
      <div className={styles.s2container}>
        <Typography component="h1" gutterBottom className={styles.h1}>
          <span className={styles.span}>{data?.title}</span>
        </Typography>
        <Typography gutterBottom className={styles.p}>
          {data?.description}
        </Typography>
        {/* <Divider className={styles.divider} /> */}
        <Box className={styles.boxcontainer}>
          <Box className={styles.boxImg}>
            <img
              src={data.image}
              style={{
                width: "80%",
                height: "auto",
                objectFit: "cover !important",
              }}
              alt="Detail"
            />
          </Box>
          <Box className={styles.boxInfo}>
            {details &&
              Array.isArray(details) &&
              details.map((detail, detailIndex) => (
                <List key={detailIndex} className={styles.list}>
                  <Typography gutterBottom className={styles.h3}>
                    <span className={styles.span}>{detail.list_title}</span>
                  </Typography>
                  {detail.list_content &&
                    Array.isArray(detail.list_content) &&
                    detail.list_content.map((content, contentIndex) => (
                      <ListItem key={contentIndex} className={styles.item}>
                        <LabelImportantIcon />
                        <ListItemText
                          primary={
                            <Typography className={styles.text}>
                              {content?.text}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                </List>
              ))}
          </Box>
        </Box>
        {summary && (
          <div className={styles.summary}>
            <Typography gutterBottom className={styles.h2}>
              <span className={styles.span}>
                <AdsClickIcon
                  sx={{
                    fontSize: "large",
                    margin: "0px 10px 0px 10px",
                    color: "#4074b0",
                  }}
                ></AdsClickIcon>
                {summary?.summary_title}
              </span>
            </Typography>
            {summary.summary_details &&
              Array.isArray(summary.summary_details) &&
              summary.summary_details.map((detail, detailIndex) => (
                <List key={detailIndex} className={styles.list}>
                  <Typography gutterBottom className={styles.h4}>
                    <span className={styles.span}>{detail?.list_title}</span>
                  </Typography>
                  {detail.list_content &&
                    Array.isArray(detail.list_content) &&
                    detail.list_content.map((content, contentIndex) => (
                      <ListItem key={contentIndex} className={styles.item}>
                        <LabelImportantIcon />
                        <ListItemText
                          primary={
                            <Typography className={styles.text}>
                              {content?.text}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                </List>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SolSection;
