import React from "react";
import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";

import SolSection from "../../../components/Solutions/OurProducts/SolSection";
import TwoBoxes from "../../../components/Solutions/OurProducts/TwoBoxes";
import Layout from "../../../components/Layout";
import GoToTopBtn from "../../../components/GoToTopBtn";

import styles from "./OurProducts.module.scss";

const OurProducts = () => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("OurProducts");
  const smartConsumption = t("smartConsumption", { returnObjects: true });
  const scBanner = smartConsumption.banner;
  const scSlogan = smartConsumption.slogan;
  const scLegal = smartConsumption.legal;
  const scSmartBooking = smartConsumption.productInfo_01;
  const scAiAsistant = smartConsumption.productInfo_02;
  const smartMarket = t("smartMarket", { returnObjects: true });
  const smBanner = smartMarket.banner;
  const smSlogan = smartMarket.slogan;
  const smSaas = smartMarket.productInfo_01;
  const btnInfo = t("btnInfo", { returnObjects: true });
  const learnMoreBtn = smartMarket.learn_more_btn;

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <>
      <Layout>
        <div className={styles.s1wrap}>
          <div className={styles.imgwrap}>
            <img
              src={isTabletOrMobile ? scBanner?.mImageSrc : scBanner?.imageSrc}
              alt="Detail"
              className={styles.img}
            />
          </div>
        </div>
        <div className={styles.s2wrap}>
          <div className={styles.s2container}>
            <Typography component="h1" gutterBottom className={styles.h1}>
              {scSlogan?.title}
            </Typography>
            <Typography gutterBottom className={styles.p}>
              {scSlogan?.description}
              <a
                href="/service-terms"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.a}
              >
                {scLegal?.content}
                <sup className={styles.sup}>{scLegal?.superscript}</sup>
              </a>
            </Typography>
          </div>
          <SolSection
            data={scSmartBooking}
            backgroundColor={"#fff"}
            index={0}
          ></SolSection>
          <SolSection
            data={scAiAsistant}
            backgroundColor={"#fff"}
            index={1}
          ></SolSection>
        </div>

        {/*Section 3*/}
        <div className={styles.s5wrap}>
          <div className={styles.s5container}>
            <Button
              variant="outlined"
              color="primary"
              href={"/demo"}
              className={styles.btn}
            >
              {btnInfo.demo}
            </Button>
            <Button
              color="primary"
              href={"/contact-us"}
              className={styles.btn}
              variant="outlined"
              disabled
            >
              {btnInfo.join}
            </Button>
          </div>
        </div>
        {/*Section 4*/}
        <div className={styles.s1wrap}>
          <div className={styles.imgwrap}>
            <img
              src={isTabletOrMobile ? smBanner?.mImageSrc : smBanner?.imageSrc}
              alt="Detail"
              className={styles.img}
            />
          </div>
        </div>
        <div className={styles.s2wrap}>
          <div className={styles.s2container}>
            <Typography component="h1" gutterBottom className={styles.h1}>
              <span className={styles.span}>{smSlogan?.title}</span>
            </Typography>
            <Typography gutterBottom className={styles.p}>
              {smSlogan?.description}
            </Typography>
          </div>
          <TwoBoxes
            data={smSaas}
            backgroundColor={"#fff"}
            btnColor={"#4074b0"}
            fontColor={"#2e1042"}
            index={1}
          ></TwoBoxes>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNavigate(learnMoreBtn?.link)}
            className={styles.s4btn}
          >
            {learnMoreBtn?.text}
          </Button>
        </div>
      </Layout>
      <GoToTopBtn />
    </>
  );
};
export default OurProducts;
