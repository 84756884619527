import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
/* import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
 */
import { Divider } from '@mui/material'
import styles from './HelpInfo.module.scss'

function HelpInfo({ title, details }) {
  const [expanded, setExpanded] = useState(false)

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded)
  }
  return (
    <div className={styles.wrap}>
      <Accordion
        className={styles.accordion}
        onChange={handleAccordionChange}
        sx={{
          borderRadius: expanded
            ? '10px 10px 10px 10px !important'
            : '40px !important',
          transition: 'border-radius 0.3s ease', // 添加过渡效果
          '&.Mui-expanded': {
            margin: '0 !important',
          },
        }}
      >
        <AccordionSummary
          className={styles.title}
          expandIcon={<ExpandMoreIcon fontSize='large' />}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          {title}
        </AccordionSummary>
        <AccordionDetails
          className={styles.details}
          sx={{
            borderRadius: ' 0px 0px 10px 10px !important',
          }}
        >
          {details}
        </AccordionDetails>
      </Accordion>
      <Divider className={styles.divider}></Divider>
    </div>
  )
}

export default HelpInfo
