import React from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import Footer from './Footer'
import styles from './Layout.module.scss'

function Layout({ children, footer = true }) {
  return (
    <div className={styles.App}>
      <Header />
      <main>{children}</main>
      {footer ? <Footer /> : null}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  footer: PropTypes.bool,
}

export default React.memo(Layout)
