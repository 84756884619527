import React from "react";
import styles from "./SpeechBubbleCard.module.scss";

const SpeechBubbleCard = ({
  speechBubbleImage,
  brushStrokeImage,
  textKey,
  fontColor,
}) => {
  return (
    <div className={styles.speechBubbleCard}>
      <div className={styles.backgroundImage}>
        <img
          src={process.env.PUBLIC_URL + brushStrokeImage}
          alt="Brush Stroke"
          className={styles.backgroundImg}
        />
      </div>
      <div className={styles.speechBubble}>
        <img
          src={process.env.PUBLIC_URL + speechBubbleImage}
          alt="Speech Bubble"
        />
      </div>
      <div className={styles.speechBubbleText} style={{ color: fontColor }}>
        {textKey}
      </div>
    </div>
  );
};

export default SpeechBubbleCard;
