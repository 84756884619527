import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
} from "@mui/material";

import styles from "./FeatureBar.module.scss";

const FeatureBar = ({ index, title, content, image, infoBackgroundColor }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderContent = () => (
    <Grid
      item
      xs={12}
      sm={8}
      className={styles.grid_info}
      sx={{ backgroundColor: infoBackgroundColor }}
    >
      <div className={styles.wrap_info}>
        <Typography className={styles.h1}>{title}</Typography>
        <Typography className={styles.p}>{content}</Typography>
      </div>
    </Grid>
  );

  const renderImage = () => (
    <Grid item xs={12} sm={4} className={styles.grid_img}>
      <img
        src={image}
        alt={title}
        style={{ width: "200px", height: "200px", objectFit: "cover" }}
      />
    </Grid>
  );

  return (
    <Paper sx={{ borderRadius: "20px", overflow: "hidden" }}>
      <Grid container alignItems="stretch" style={{ height: "100%" }}>
        {isMobile ? (
          <>
            {renderImage()}
            {renderContent()}
          </>
        ) : (
          <>
            {index % 2 === 0 ? (
              <>
                {renderImage()}
                {renderContent()}
              </>
            ) : (
              <>
                {renderContent()}
                {renderImage()}
              </>
            )}
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default FeatureBar;
