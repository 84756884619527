import React, { useState } from "react";
import { Typography, Box, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./TwoBoxes.module.scss";
import SpeechBubbleCard from "./SpeechBubbleCard";
import QuestionCard from "./QuestionCard";

const TwoBoxes = ({ data, backgroundColor, btnColor, fontColor, index }) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const details = data.details;
  const [showAfterStates, setShowAfterStates] = useState(
    details.map(() => false)
  );

  const handleToggle = (index) => {
    setShowAfterStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };
  return (
    <div className={styles.s2wrap} style={{ backgroundColor }}>
      <div className={styles.s2container}>
        {/* <Divider className={styles.divider} /> */}
        <Box className={styles.boxcontainer}>
          {(index === 0 || isTabletOrMobile) && (
            <Box className={styles.boxImg}>
              <img
                src={isTabletOrMobile ? data?.mImage : data?.image}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover !important",
                }}
                alt="Detail"
              />
            </Box>
          )}
          <Box className={styles.boxInfo}>
            {details &&
              Array.isArray(details) &&
              details.map((detail, detailIndex) => (
                <List key={detailIndex} className={styles.list}>
                  <Typography gutterBottom className={styles.h3}>
                    <span className={styles.span}>{detail.list_title}</span>
                  </Typography>
                  {showAfterStates[detailIndex] ? (
                    <SpeechBubbleCard
                      speechBubbleImage={detail?.after?.speech_bubble}
                      brushStrokeImage={detail?.after?.brush_image}
                      textKey={detail?.after?.content}
                      fontColor={fontColor}
                    />
                  ) : (
                    <SpeechBubbleCard
                      speechBubbleImage={detail?.before?.speech_bubble}
                      brushStrokeImage={detail?.before?.brush_image}
                      textKey={detail?.before?.content}
                    />
                  )}
                  <QuestionCard
                    speechBubbleImage={detail?.question?.speech_bubble}
                    textKey={detail?.question?.content}
                    onButtonClick={() => handleToggle(detailIndex)}
                    showAfter={showAfterStates[detailIndex]}
                    btnColor={btnColor}
                  />
                </List>
              ))}
          </Box>
          {index === 1 && !isTabletOrMobile && (
            <Box className={styles.boxImg}>
              <img
                src={data.image}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover !important",
                }}
                alt="Detail"
              />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default TwoBoxes;
