import React, { useState, useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { Typography, Box, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SpeechBubbleCard from "./SpeechBubbleCard";
import QuestionCard from "./QuestionCard";
import styles from "./SolSection.module.scss";

const SolSection = ({ data, backgroundColor, index }) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const details = data.details;
  const [showAfterStates, setShowAfterStates] = useState(
    details.map(() => false)
  );
  const nodeRefs = useRef(details.map(() => React.createRef()));

  const handleToggle = (index) => {
    setShowAfterStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <div className={styles.s2wrap} style={{ backgroundColor }}>
      <div className={styles.s2container}>
        {/* <Divider className={styles.divider} /> */}
        <Box className={styles.boxcontainer}>
          {(index === 0 || isTabletOrMobile) && (
            <Box className={styles.boxImg}>
              <img
                src={data.image}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover !important",
                }}
                alt="Detail"
              />
            </Box>
          )}
          <Box className={styles.boxInfo}>
            {details &&
              Array.isArray(details) &&
              details.map((detail, detailIndex) => (
                <List key={detailIndex} className={styles.list}>
                  <Typography gutterBottom className={styles.h3}>
                    <span className={styles.span}>{detail.list_title}</span>
                  </Typography>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={showAfterStates[detailIndex] ? "after" : "before"}
                      timeout={500}
                      classNames="fade"
                      nodeRef={nodeRefs.current[detailIndex]}
                      addEndListener={(done) => {
                        nodeRefs.current[detailIndex].current.addEventListener(
                          "transitionend",
                          done,
                          false
                        );
                      }}
                    >
                      <div ref={nodeRefs.current[detailIndex]}>
                        {showAfterStates[detailIndex] ? (
                          <SpeechBubbleCard
                            speechBubbleImage={detail?.after?.speech_bubble}
                            brushStrokeImage={detail?.after?.brush_image}
                            textKey={detail?.after?.content}
                            fontColor={"#2e1042"}
                          />
                        ) : (
                          <SpeechBubbleCard
                            speechBubbleImage={detail?.before?.speech_bubble}
                            brushStrokeImage={detail?.before?.brush_image}
                            textKey={detail?.before?.content}
                          />
                        )}
                      </div>
                    </CSSTransition>
                  </SwitchTransition>
                  <QuestionCard
                    speechBubbleImage={detail?.question?.speech_bubble}
                    textKey={detail?.question?.content}
                    onButtonClick={() => handleToggle(detailIndex)}
                    showAfter={showAfterStates[detailIndex]}
                    btnColor={"#1ea19c"}
                  />
                </List>
              ))}
          </Box>
          {index === 1 && !isTabletOrMobile && (
            <Box className={styles.boxImg}>
              <img
                src={data.image}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover !important",
                }}
                alt="Detail"
              />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default SolSection;
